<template>
  <div
    class="content d-flex align-center justify-center"
    style="gap: 20px; height: calc(100vh - 350px); overflow-y: auto"
  >
    <img :src="sendImage" style="max-height: 250px" />
    <div class="d-flex align-center flex-column" style="gap: 30px">
      <div class="text-h5 font-weight-semibold" v-visible="!loading">
        אם הכל מוכן אז...
      </div>
      <cz-button
        class="font-weight-bold text-body-1"
        :title="$t('vendor.orderSubmissions.sendSubmissionButtonTitle')"
        :elevation="4"
        color="info"
        x-large
        :icon-src="mdiSendCheckOutline"
        :icon-size="26"
        @click="$emit('send')"
        :loading="loading"
        :disabled="!canSendSubmission"
      />
    </div>
  </div>
</template>

<script>
import sendImage from '@/assets/illustrations/send.svg';
import { CzButton } from '@/components';
import { mdiSendCheckOutline } from '@mdi/js';
export default {
  name: 'SubmissionSummary',
  components: {
    CzButton
  },
  props: {
    submission: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    canSendSubmission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mdiSendCheckOutline,
      sendImage
    };
  }
};
</script>

<style lang="scss" scoped></style>
